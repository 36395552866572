import React, { Component,  Fragment } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

import ScrollTransitionIn from 'components/ScrollTransitionIn';
import Newsletter from 'components/Newsletter';
import Media from 'components/Media';
import Img from 'gatsby-image';
import Image from 'components/Image'

import './NewsletterBlock.scss';

export class NewsletterBlock extends Component {

	state = {
		value: null
	}

	validate (value) {
		return value && /\S+@\S+\.\S+/.test(value);
	}

	className () {
		let className = 'newsletter-block';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		if (this.props.ctaPosition) {
			className += ' cta-' + this.props.ctaPosition;
		}
		return className;
	}

	render() {
		const { headline, id, image, text } = this.props;
		const { value } = this.state;
		
		return (

				<section className={this.className()} >
					<div className="section-content">
						<div className="container">
							<div className="newsletter-box">
								<ScrollTransitionIn once>
									<h5 className="script-header underlined mb-2">{headline}</h5>
									<h3 className="my-2 mx-auto">{text.text}</h3>
									<div className="mt-3 mb-1 form-wrap">
										<Newsletter
											inputClassName="framed light large"
										/>
									</div>
								</ScrollTransitionIn>
							</div>
						</div>
					</div>
					<div className="section-bg">
						<Image {...image} />
					</div>
				</section>

		);
	}
}

export default NewsletterBlock;
