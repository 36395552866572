import React from 'react'

import PropTypes from 'prop-types'
import IllustrationPlane from 'components/IllustrationPlane';
import GlobeSection from 'components/GlobeSection';


const IllustrationRender = ({
	illustrationType,
	...props
}) => {
	if (illustrationType === 'plane') {
		return (
			<IllustrationPlane className="mb-margin" />
		)
	} else {
		return (
			<GlobeSection
				id="find-monopolio"
				setSection={props.setSection}
				className="bg-bg-color"
				showCta={false}
				lang={props.lang}
			/>
		)
	}
}

IllustrationRender.propTypes = {
	illoType: PropTypes.any,
}

export default IllustrationRender
