import React, { Component } from 'react';

import ScrollTransitionIn from 'components/ScrollTransitionIn';
import Cloud1 from './components/Cloud1';
import Cloud2 from './components/Cloud2';
import Cloud3 from './components/Cloud3';
import Cloud4 from './components/Cloud4';
import Cloud5 from './components/Cloud5';
import Plane from './components/Plane';
import Text1 from './components/Text1';
import Text2 from './components/Text2';

/// AROUND THE PLANE
// La mejor cerveza
// Siempre por encima de todas
///

import './IllustrationPlane.scss';

export class IllustrationPlane extends Component {

	className () {
		let className = 'block-illustration illustration-plane';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

	render() {

		return (
			<section className={this.className()}>
				<div>
					<div className="text-1"><div className="plane-text">La Mejor<br/>Cerveza</div></div>
					<div className="text-2"><div className="plane-text">Siempre Por<br/>Encima De Todas</div></div>
					<div className="animate-float delay-3 cloud-1"><Cloud1 /></div>
					<div className="animate-float delay-2 cloud-2"><Cloud2 /></div>
					<div className="animate-float cloud-left"><Cloud3 /></div>
					<div className="animate-float delay-3 cloud-right"><Cloud5 /></div>
					<div className="animate-float plane"><Plane /></div>
					<div className="animate-float delay-3 cloud-4"><Cloud4 /></div>
				</div>
			</section>
		);

	}
}

export default IllustrationPlane;