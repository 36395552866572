import React, { Component, Fragment } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import withSizes from 'react-sizes'
import MobileDetect from 'mobile-detect'
import ScrollableAnchor from 'react-scrollable-anchor';

import { Button, Input } from 'gw-ui';

import Image from 'components/Image'
import Media from 'components/Media';
import Img from 'gatsby-image';

import './ATF.scss';

export class ATF extends Component {

	state = {
		minHeight: null
	}

	className () {
		let className = 'atf-block';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

	shouldComponentUpdate (prevProps, prevState) {
		const md = new MobileDetect(window.navigator.userAgent)
		if ( md.is('iPhone') && prevProps.winHeight !== this.props.winHeight ) {
			return false
		}

		return true
	}

	componentDidMount = () => {
		this.setState({minHeight: this.props.winHeight})
	}

	render() {
		let { text, id, image, imageLarge, ctaPosition, winHeight, headline } = this.props;

		// console.log('ATF : ', this.props)

		let minHeight = '100vh';

		if ( this.state.minHeight ) {
			minHeight = this.state.minHeight + 'px';
		} 
			
		return (
			<section className={this.className()} id="intro">
				<div className="section-content" style={{ minHeight: minHeight }}>
					<div className="container transition-in stagger-in-4">
						<h1 className="title">{headline}</h1>
					</div>
				</div>
				<div className="section-bg">
					<Image {...image} />
				</div>
			</section>
		);

	}
}

const sizesToProps = ({ height }) => ({
	winHeight: height
})

export default withSizes(sizesToProps)(ATF)