import React, { Component } from 'react';

import ScrollTransitionIn from 'components/ScrollTransitionIn';
import Globe from './components/Globe';
import { Button } from 'gw-ui'
import './GlobeSection.scss';

export class GlobeSection extends Component {

	className () {
		let className = 'globe-section block-section';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

	render() {
		const { sectionId, setSection, id, lang } = this.props
		return (
			<section className={this.className()}>
				<div className="container">
					<ScrollTransitionIn once>
						<Globe />
						<div>
							{ lang === 'es-MX' ? ( 
									<Button label="Síguenos" className="secondary" href="https://instagram.com/cervezamonopolio" target="_blank"/>
								) : (
									<Button label="Follow Us" className="secondary" href="https://instagram.com/cervezamonopolio" target="_blank"/>
								)
							}
						</div>
					</ScrollTransitionIn>
				</div>
			</section>
		);

	}
}

export default GlobeSection;