import React from 'react'
import { useInView } from 'react-intersection-observer'
import './ScrollTransitionIn.scss'

const ScrollTransitionIn = ({
	inViewClass,
	className,
	children
}) => {
	const [ref, inView] = useInView({ triggerOnce: true })

	const getClassName = () => {
		let renderedClassName = 'scroll-transition-in'
		if (inViewClass) {
			renderedClassName = ''
		}
		if (inView) {
			renderedClassName += ' in-view'
		}
		if (className) {
			renderedClassName += ' ' + className
		}
		return renderedClassName
	}

	return (
		<div ref={ref} className={getClassName()}>
			{children}
		</div>
	)
}

export default ScrollTransitionIn