import React, { Component } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Img from 'gatsby-image';
import Image from 'components/Image'

import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollTransitionIn from 'components/ScrollTransitionIn';
import HrDecorative from 'components/HrDecorative';

import './BeerSection.scss';

export class BeerSection extends Component {

	className () {
		let className = 'beer-section';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		if (this.props.theme) {
			className += ' ' + this.props.theme;
		}
		return className;
	}

	render() {

		const { headline, text, id, abv, cals, bu, imagePosition, image, setSection, thumbnailImage } = this.props;

		return (
			<section className={this.className()} id={headline === 'Lager Clara' ? 'cerveza' : '' }>
				<div className="container">
					<div className={ imagePosition === 'right' ? "grid-flex break-sm gutter-wide right" : "grid-flex break-sm gutter-none"}>
						<div className={headline === 'Lager Clara' ? 'col align-center text-col' : 'col align-center text-col green' }>
							<ScrollTransitionIn once>
								{thumbnailImage ? (
									<div className="thumbnail">
									<Image {...thumbnailImage} />
									</div>
								) : false}
								{headline ? (
									<div>
										<h1 className="mb-2 title pb-1">{headline}</h1>
										<HrDecorative className="my-2" />
									</div>
								) : false}
								{text ? (
									<div className="text mt-2 mb-3 pt-1" >
										<p className="font-secondary">{text.text}</p>
									</div>
								) : false}
								<div className={"stats " + imagePosition}>
									<div className={"grid-flex no-break center"}>
										<div className="col">
											<span className="label small-caps">Abv</span>
											<div className="value h4">{abv}</div>
										</div>
										<div className="col">
											<span className="label small-caps">Cals</span>
											<div className="value h4">{cals}</div>
										</div>
										<div className="col">
											<span className="label small-caps">Bu</span>
											<div className="value h4">{bu}</div>
										</div>
									</div>
								</div>
							</ScrollTransitionIn>
						</div>
					</div>

				</div>
				<div className="section-bg">
					<Image {...image} />
				</div>
			</section>
		
		);

	}
}

BeerSection.defaultProps = {
	imagePosition: 'left'
}

export default BeerSection;