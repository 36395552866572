import React from 'react'
import styled from '@emotion/styled'

const verticalPadding = 20
const mediumSpeed = '0.3s'
const mainColor = '#3CABC1'

const InnerWrapper = styled.div`
  width: 100%;
  text-align: left;
`

const UnstyledButton = styled.button`
	appearance: none;
	line-height: unset;
  width: 100%;
  border: none;
  background: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  padding: inherit;
  margin: inherit;
  border-radius: unset;
  box-shadow: unset;
  background: unset;
  color: inherit;
  height: unset;
  padding: 0;
  &:not(.disabled):not(.loading):hover,
  &:hover,
  &:focus {
  	color: ${ mainColor };
  	border-radius: unset;
	  box-shadow: unset;
	  background: unset;
  }
`

const CollapseHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`

const DisplayName = styled.div`
  font-size: .8em;
  font-family: 'Franklin', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  font-weight: 700;
  line-height: 1.4em;
  display: inline-block;
  vertical-align: top;
  border-bottom: 2px solid ${ mainColor };
  padding-bottom: .1em;
`
const DetailsContainer = styled.div`
  &.open {
    height: ${ ({ scrollHeight }) => scrollHeight }px;
  }
  overflow: hidden;
  height: 0px;
  transition: height ${ mediumSpeed } ease-in-out;
`

const DetailsContent = styled.div`
  max-width: 600px;
	text-align: left;
  padding-bottom: ${ verticalPadding + 'px' };
`

class ReadMore extends React.Component {
	constructor (props) {
		super(props)
		this.state = { open: false }
		this.ItemContent = React.createRef()
	}

	componentDidMount () {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', this.updateDetailsHeight)
		}
	}

	componentWillUnmount () {
		window.removeEventListener('scroll', this.updateDetailsHeight)
	}

  toggleOpen = () => {
		if (typeof window !== 'undefined') {
			this.updateDetailsHeight()
			this.setState((prevState) => ({ open: !prevState.open }))
		}
  }

  updateDetailsHeight = () => {
		const { scrollHeight } = this.ItemContent.current
		this.setState({ scrollHeight })
  }

  render () {
		const { children, title, index } = this.props
		const { open, scrollHeight } = this.state
		return (
			<InnerWrapper index={index}>
				
				<DetailsContainer ref={this.ItemContent} className={open ? 'open' : ''} open={open} scrollHeight={scrollHeight}>
					<DetailsContent>
						{children}
					</DetailsContent>
				</DetailsContainer>
				<UnstyledButton onClick={this.toggleOpen}>
					<CollapseHeader>
						<DisplayName>{title}</DisplayName>
					</CollapseHeader>
				</UnstyledButton>
			</InnerWrapper>
		)
	}
}

ReadMore.defaultProps = {
	index: null
}

export default ReadMore;