import React, { Component } from 'react';

import Illo from 'assets/svg/cloud-1.svg';

class Cloud1 extends Component {

  render() {
    return (
      <div className="illo-wrap">
      	<Illo />
      </div>
    );
  }
}

export default Cloud1;