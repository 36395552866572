import React, { Component } from 'react';

import Illo from 'assets/svg/plane-text-2.svg';

class Text2 extends Component {

  render() {
    return (
			<div className="illo-wrap">
      	<Illo />
      </div> 
    );
  }
}

export default Text2;