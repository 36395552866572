import React, { Component } from 'react';
import ScrollTransitionIn from 'components/ScrollTransitionIn';

import './HrDecorative.scss';

class HrDecorative extends Component {

	render () {
		return (
			<div className={this.props.className ? this.props.className + " hr-decorative" : "hr-decorative"}>
				{this.props.capLeft !== false && (
					<div className="left-cap">
						<svg width="12px" height="8px" viewBox="0 0 12 8">
						    <g id="Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						        <path d="M9.70255661,0 C11.0538273,0 12,0.687768928 12,2.84098015 C12,8.00598994 3.5467681,8 3.5467681,8 L3.5467681,8 L0,8 L0,7 L1.35272382,7.06388204 C7.68131634,7.06388204 9.51983762,5.03015314 9.51983762,3.77298946 C9.51983762,3.77298946 7.83429038,3.79097034 7.83429038,1.97789757 C7.83429038,0.952987011 8.49292859,0 9.70255661,0 Z M4,3 L4,4 L1.13686838e-13,4 L1.13686838e-13,3 L4,3 Z" id="Combined-Shape" fill="#C3A27A" transform="translate(6.000000, 4.000000) scale(-1, 1) translate(-6.000000, -4.000000) "></path>
						    </g>
						</svg>
					</div>
				)}
				<div className="line"/>
				{this.props.capRight !== false && (
					<div className="right-cap">
						<svg width="12px" height="8px" viewBox="0 0 12 8">
					    <g id="Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				        <path d="M9.70255661,0 C11.0538273,0 12,0.687768926 12,2.84098014 C12,8.00598992 3.5467681,8 3.5467681,8 L3.5467681,8 L0,8 L0,7 L1.35272382,7.06388202 C7.68131634,7.06388202 9.51983762,5.03015313 9.51983762,3.77298945 C9.51983762,3.77298945 7.83429038,3.79097033 7.83429038,1.97789757 C7.83429038,0.952987009 8.49292859,0 9.70255661,0 Z M4,2.99999999 L4,3.99999999 L1.13686838e-13,3.99999999 L1.13686838e-13,2.99999999 L4,2.99999999 Z" id="Combined-Shape-Copy" fill="#BE996E" transform="translate(6.000000, 4.000000) scale(1, -1) translate(-6.000000, -4.000000) "></path>
					    </g>
						</svg>
					</div>
				)}
			</div>
		)
	}
}

export default HrDecorative;