import React, { Component } from 'react';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import './Media.scss';

export class Media extends Component {

	static defaultProps = {
		width: 3,
		height: 2
	}

	className () {
		let className = 'media-item lazy-wrap';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

	render() {

		const { image, imageLarge, width, height, alt } = this.props

		let lazyImage = { };

		let breakpoint = this.props.imageBreakpoint || 1400;

    if (image === null) {
      lazyImage = {
        normal: 'placeholder.png',
        retina: 'placeholder.png',
      }
    } else {
      lazyImage = {
        normal: image,
        retina: imageLarge,
      }
    }

    let LazysizesImg = () => (
      <picture className="img">
      	{lazyImage.retina ? (
					<source
						data-srcset={lazyImage.retina}
						media={"(min-width: " + breakpoint + "px)"}
					/>
				) : false}
        <img
        	data-sizes="auto"
          data-src={lazyImage.normal}
          className="lazyload"
          alt={alt}
        />
      </picture>
    );

		return (
			<div className={this.className()} style={{ paddingBottom: height / width * 100 + '%' }}>
				{LazysizesImg()}
      </div>
		);

	}
}

export default Media;