import React, { Component } from 'react';

import Illo from 'assets/svg/plane.svg';

class Plane extends Component {

  render() {
    return (
			<div className="illo-wrap">
      	<Illo />
      </div> 
    );
  }
}

export default Plane;