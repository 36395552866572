import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import {Helmet} from 'react-helmet'

import IllustrationPlane from 'components/IllustrationPlane';
import ATF from 'components/ATF';
import ComponentRenderer from 'components/ComponentRenderer';
import Header from 'components/Header';
import Footer from 'components/Footer';

class PageTemplate extends Component {

	state = {
		loading : false,
		title : false,
		introText : false,
		introImage : false,
		emailContact : false,
		blocks : [],
		currentSection : '',

	}

	setSection = (section) => {
  	if(section !== this.state.currentSection) {
		  this.setState({ currentSection: section })
		}
  }

  setFooterLocale = (locale) => {
  	if ( locale === 'en-US') {
  		return (
  			<Footer {... this.props.data.usSettings.edges[0].node} />
  		)
  	} else {
  		return (
  			<Footer {... this.props.data.mxSettings.edges[0].node} />
			)
  	}
  }

  setHelmetLocale = (locale, page, rootPath) => {
  	// console.log('this props data usSettings : ', this.props.data.usSettings)
  	let title;
  	let favicon;
  	let og_locale;
  	let touchIcon;
  	let defaultShareImage;
  	if( locale === 'en-US') {
  		title = this.props.data.usSettings.edges[0].node.title
  		favicon = this.props.data.usSettings.edges[0].node.favicon.file.url
  		touchIcon = this.props.data.usSettings.edges[0].node.touchIcon.file.url
  		defaultShareImage = this.props.data.usSettings.edges[0].node.defaultShareImage.file.url
  		og_locale = 'en_US';
  	} else {
  		title = this.props.data.mxSettings.edges[0].node.title
  		favicon = this.props.data.mxSettings.edges[0].node.favicon.file.url
  		touchIcon = this.props.data.mxSettings.edges[0].node.touchIcon.file.url
  		defaultShareImage = this.props.data.usSettings.edges[0].node.defaultShareImage.file.url
  		og_locale = 'es_MX';
  	}

  	return (
  		<Helmet>
					<meta charset="utf-8"/>
					<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
	  			<title>{title}</title>
	  			<link rel="shortcut icon" href={favicon}/>
					<meta name="description" content={page.seoDescription} />
					<link rel="apple-touch-icon-precomposed" href={'https:' + touchIcon} />
					<link rel="canonical" href={rootPath} />
					<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
					<meta property="og:image" content={'https:' + defaultShareImage} />
					<meta property="og:locale" content={og_locale} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={page.seoDescription}/>
					<meta property="og:url" content={rootPath} />
					<meta property="og:site_name" content={title} />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:description" content={page.seoDescription} />
					<meta name="twitter:title" content={title} />
        </Helmet>
  	)
  }

	render() {
		const page = this.props.data.allContentfulPage.edges[0].node;
		const sections = page.sections;
		const rootPath = this.props.location.href;

		return (
			<div>
				{/* Helmet */}
				{this.setHelmetLocale(page.node_locale, page, rootPath)}
				
				{/* Header */}
				<Header lang={page.node_locale}/>
				{/* ComponentRenderer */}
				{sections.map(b => <ComponentRenderer key={b.id} item={b} lang={page.node_locale}/>)}
				{/* Footer */}
				{ this.setFooterLocale(page.node_locale)}
			</div>
		);
	}
}

export const pageQuery = graphql`
	query($id: String!) {
		usSettings : allContentfulSiteSettings(filter: {node_locale: {eq: "en-US"}}) {
			edges {
				node {
					title
					footerSections {
	          id
	          displayTitle
	          description {
	          	json
	          }
	          ... on ContentfulList {
	            id
	            items {
	             ... on ContentfulButton {
	             	 	id
	              	label
	              	to
	              	external
	              	theme
	            	} 
	            }
	          }
	        }
	        defaultShareImage {
	          id
	          file {
	            url
	          }
	        }
	        favicon {
	        	file {
	        		url
	        	}
					}
	        footerNewsletterTitle
	        footerNewsletterText {
	          footerNewsletterText
	        }
	        showNewsletterSignUp
	        touchIcon {
	          file {
	            url
	          }
	        }
				}
			}
		}
		mxSettings : allContentfulSiteSettings(filter: {node_locale: {eq: "es-MX"}}) {
			edges {
				node {
					title
					footerSections {
	          id
	          displayTitle
	          description {
	          	json
	          }
	          ... on ContentfulList {
	            id
	            items {
	             ... on ContentfulButton {
	             	 	id
	              	label
	              	to
	              	external
	              	theme
	            	} 
	            }
	          }
	        }
	        defaultShareImage {
	          id
	          file {
	            url
	          }
	        }
	        favicon {
	        	file {
	        		url
	        	}
					}
	        footerNewsletterTitle
	        footerNewsletterText {
	          footerNewsletterText
	        }
	        showNewsletterSignUp
	        touchIcon {
	          file {
	            url
	          }
	        }
				}
			}
		}
		allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					node_locale
					path
					title
					seoDescription
					
					sections {
						... AboveTheFold
						... CenterAlignedText
	          ... FiftyFifty 
	          ... BeerSection 
	          ... Slideshow 
	          ... ThreeUp
	          ... Newsletter
	          ... Illustration
	          ... ImageFeed
					}

				}
			}
		}

	}
`

export default PageTemplate