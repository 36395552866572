import React, { Component } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ScrollableAnchor from 'react-scrollable-anchor';
import Img from 'gatsby-image';
import Image from 'components/Image'

import ScrollTransitionIn from 'components/ScrollTransitionIn';
import Media from 'components/Media';
// import Grid from 'components/Grid';

// import Factory from 'assets/images/mono-factory.png';
import './ImageText.scss'

export class ImageText extends Component {

	className () {
		let className = 'block-section image-text pb-1px bg-white';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		if (this.props.imageBleed) {
			className += ' image-bleed';
		}
		return className;
	}

	render() {
		let { eyebrow, headline, text, id, sectionId, imageBleed, image, imageCaption, imagePosition, smallText, contentBelow, setSection } = this.props;

		// image = Factory;
			
			// <ScrollableAnchor id={id}>
			//  setSection={() => { setSection(sectionId ? sectionId : id) }} unsetSection={() => { setSection('') }}

			// </ScrollableAnchor>

										// <div className="rich-text lg font-secondary" dangerouslySetInnerHTML={{ __html: documentToHtmlString(text.json) }} />
			
		return (
				<section className={this.className()}>
					<div className="container">
						<ScrollTransitionIn className="grid-flex gutter-none middle break-lg in-view" once>
							<div className={ imagePosition === 'right' ? "col order-2 img-col" : "col img-col" }>
								<ScrollTransitionIn once>
									<div className="media-wrap">
										<Image {...image} />
										{/*
										<Img fluid={image.fluid} />
										*/}
									</div>
									{imageCaption ? (
										<div className="caption">
											<div className="grid-flex gutter-none no-break left baseline mt-1 pt-1">
												<div className="col no-grow">
													<span className="color-main-color mr-1"><span className="diamond-icon" /></span>
												</div>
												<div className="col no-grow">
													<p className="sm medium-weight">{imageCaption}</p>
												</div>
											</div>
										</div>
									) : false}
								</ScrollTransitionIn>
							</div>
							<div className={ imagePosition === 'right' ? "col order-1 text-col" : "col text-col" }>
								<ScrollTransitionIn once>
									{eyebrow ? (
										<h6 className="mb-3 underlined eyebrow">{eyebrow}</h6>
									) : false}

									{headline ? (
										<h2 className="h3 mtn-2 mb-2 title">{headline}</h2>
									) : false}

									{text ? (

										<div className="rich-text lg font-secondary" dangerouslySetInnerHTML={{ __html: documentToHtmlString(text.json) }} />
										
									) : false}

									{smallText ? (
										<div className="rich-text medium-weight color-main-color" dangerouslySetInnerHTML={{ __html: smallText }} />
									) : false}

									{contentBelow ? (contentBelow) : false}
								</ScrollTransitionIn>
							</div>
						</ScrollTransitionIn>
					</div>
				</section>
		);

	}
}

export default ImageText;