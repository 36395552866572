import React, { Component } from 'react';

import Illo from 'assets/svg/mono_globe_trace.svg';

class Globe extends Component {

  render() {
    return (
			<div className="illo-wrap">
      	<Illo />
      </div>    
    );
  }
}

export default Globe;