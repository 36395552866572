import React, { Component, Fragment } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollTransitionIn from 'components/ScrollTransitionIn';

import './CenteredText.scss';


export class CenteredText extends Component {

	className () {
		let className = 'block-section block-padding centered-text';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}
	render() {
		const { headline, largeText, text, id, background, navItems, currentSection, emailContact, setSection } = this.props;

		return (
			<section className={this.className()} id="history">
				<div className="container align-center">
					<ScrollTransitionIn once>
						{headline ? (
							<h2 className="mt-4 mb-3 title shadowed">{headline}</h2>
						) : false}

						{text ? (
							<div className="rich-text h5" dangerouslySetInnerHTML={{ __html: documentToHtmlString(text.json) }} />
						) : false}

					</ScrollTransitionIn>
				</div>
			</section>
		);

	}
}

export default CenteredText;