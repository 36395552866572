import React, { Component } from 'react';
import ScrollTransitionIn from 'components/ScrollTransitionIn';
import Image from 'components/Image'
import ReadMore from 'components/ReadMore'

// import ReadMoreReact from 'read-more-react';
import ShowMoreText from 'react-show-more-text';

import './ThreeUp.scss';

class ThreeUp extends Component {

	state = {
		readMore: []
	}

	handleClick = (item) => {
		const { readMore } = this.state;
		var index = readMore.indexOf(item);
		if (index > -1) {
		  readMore.splice(index, 1);
		} else {
			readMore.push(item);
		}

		this.setState({readMore});
	}

	// {items.map( (item, index) => (
	// 	<div key={index} className="col-4 pb-2">
	// 		<img src={item.image} alt={item.title[lang]}/>
	// 		<h6 className="color-main-color mt-1 date">{item.date}</h6>
	// 		<h4 className="mb-1">{item.title[lang]}</h4>
	// 		<p className="my-2">{item.text[lang]}</p>
	// 		{readMore.includes(index) ? (<p className="my-2">{item.readMore[lang]}</p>) : false}
	// 		{item.readMore && !readMore.includes(index) ? (
	// 			<a onClick={() => this.handleClick(index)} className="h6 underlined read-more-link">
	// 				{lang === 'es-MX' ? 'Mas' : 'Read More'}
	// 			</a>
	// 		) : false}
	// 	</div>
	// ))}

	render () {
		// const { items, lang } = this.props;
		const { leftDate, leftHeadline, leftImage, leftText, leftItemTextMore, middleDate, middleHeadline, middleImage, middleText, middleItemTextMore, rightDate, rightHeadline, rightImage, rightText, rightItemTextMore, lang} = this.props;
		const { readMore } = this.state;
		// console.log('ThreeUP this.props : ', this.props)
		return (
			<div className="block-section three-up">
				<div className="container">
					<ScrollTransitionIn className="grid-flex v-spaced" once>
						<div key={leftImage.id} className="col-4 pb-2">
							<Image {...leftImage} />
							<h6 className="color-main-color mt-2 date">{leftDate}</h6>
							<h4 className="mb-1">{leftHeadline}</h4>
							<p className="my-2">
								{leftText.leftText}
							</p>
							<ReadMore
                title={ lang === 'en-US' ? 'Read more' : 'Mas'}
	            >
	            	<p>{leftItemTextMore.leftItemTextMore}</p>
	            </ReadMore>
						</div>

						<div key={middleImage.id} className="col-4 pb-2">
							<Image {...middleImage} />
							<h6 className="color-main-color mt-2 date">{middleDate}</h6>
							<h4 className="mb-1">{middleHeadline}</h4>
							<p className="my-2">
                {middleText.middleText}
							</p>
							<ReadMore
                title={ lang === 'en-US' ? 'Read more' : 'Mas'}
	            >
	            	<p>{middleItemTextMore.middleItemTextMore}</p>
	            </ReadMore>
						</div>

						<div key={rightImage.id} className="col-4 pb-2">
							<Image {...rightImage} />
							<h6 className="color-main-color mt-2 date">{rightDate}</h6>
							<h4 className="mb-1">{rightHeadline}</h4>
							<p className="my-2">{rightText.rightText}</p>
							<ReadMore
                title={ lang === 'en-US' ? 'Read more' : 'Mas'}
	            >
	            	<p>{rightItemTextMore.rightItemTextMore}</p>
	            </ReadMore>
						</div>

					</ScrollTransitionIn>
				</div>
			</div>
		)
	}
}

export default ThreeUp;