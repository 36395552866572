import React from 'react'


import ATF from 'components/ATF';
import CenteredText from 'components/CenteredText';
// import IllustrationPlane from 'components/IllustrationPlane';
import IllustrationRenderer from 'components/IllustrationRenderer';
import ImageText from 'components/ImageText';
import BeerSection from 'components/BeerSection';
import NewsletterBlock from 'components/NewsletterBlock';
import Slideshow from 'components/Slideshow';
import ThreeUp from 'components/ThreeUp';
import ImageFeed from 'components/ImageFeed';

// import GlobeSection from 'components/GlobeSection';
// import NewsletterBlock from 'components/NewsletterBlock';
// import Slideshow from 'components/Slideshow';
// import ThreeUp from 'components/ThreeUp';
// import InstagramFeed from 'components/InstagramFeed';


const componentMap = {
	ContentfulAboveTheFold: ATF,
	ContentfulFiftyFifty: ImageText,
	ContentfulCenterAlignedText: CenteredText,
	ContentfulBeerSection: BeerSection,
	ContentfulIllustration: IllustrationRenderer,
	ContentfulSlideshow: Slideshow,
	ContentfulThreeUp: ThreeUp,
	ContentfulNewsletter: NewsletterBlock,
	ContentfulImageFeed: ImageFeed
	// ContentfulPennant: Pennant,
	// ContentfulWideImage: WideImage
}

export default ({ item, setSection, lang }) => {
	const Component = componentMap[item.__typename]
	return Component ? (
		<Component {...item} setSection={setSection} lang={lang}/>
	) : null
}
