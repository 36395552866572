import React, { Component } from 'react';
import Slider from 'react-slick';
import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollTransitionIn from 'components/ScrollTransitionIn';
import Image from 'components/Image'

import './Slideshow.scss';


class Slideshow extends Component {

	state = {
		currentIndex: 0,
		animation: true
	}

	resetAnimation = () => {
		this.setState({ animation: false })
	}

	handleChange = (currentIndex) => {
		this.setState({ currentIndex })
		this.setState({ animation: true })
	}

	goToSlide = (index) => {
		this.slideshow.slickGoTo(index);
		this.setState({ animation: false })
	}

	goToNextSlide = () => {
    if (this.slideshow) {
      this.slideshow.slickNext()
    }
  }

  goToPrevSlide = () => {
    if (this.slideshow) {
      this.slideshow.slickPrev()
    }
  }

	render() {
		const { slides, id, lang, headline, setSection } = this.props;
		const { currentIndex } = this.state;

		const slideshowSettings = {
			dots: false,
			arrows: false,
			swipe: false,
			infinite: true,
			speed: 500,
			autoplaySpeed: 4000,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			pauseOnHover: true,
			adaptiveHeight: true,
			beforeChange: this.resetAnimation,
			afterChange: this.handleChange
		};

		// console.log('slideshow,', this.props);

			// <ScrollableAnchor id={id}>
			// setSection={() => { setSection(id) }} unsetSection={() => { setSection('') }}
			// </ScrollableAnchor>

		return (
				<section className="slideshow block-section" id="tradition">
					<div className="container">
						<ScrollTransitionIn once>
							<div className="align-center">
								<h2 className="mb-margin title shadowed color-main-color">{headline}</h2>
							</div>
							<div className="grid-flex middle gutter-wide break-lg">
								<div className="col-6 order-2">
									<Slider
										ref={c => (this.slideshow = c)} {...slideshowSettings}>
										{slides.map( (slide, index) => (
											<div key={index} className="slide">
												<Image {...slide.image} />
											</div>
										))}
									</Slider>
								</div>
								<div className="col-6 order-1 align-center">
									<div className={this.state.animation ? 'info-wrap stagger-in-items' : 'info-wrap hidden'}>
										<h5 className="mt-2 underlined script-header">{slides[currentIndex].headline}</h5>
										<p className="my-1 pt-1 slideshow-text">
											{slides[currentIndex].text.text}
										</p>
									{/*
										<ul className="dots my-2">
											{slides.map( (slide, index) => (
												<li key={index} className="dot" onClick={() => this.goToSlide(index)}>
													{index === currentIndex ? (<div className="diamond-icon active" />) : (<div className="diamond-icon" />)}
												</li>)
											)}
										</ul>
									*/}	
									</div>
								</div>
								<div className='nav-arrows'>
									<div className="prev" onClick={this.goToPrevSlide}>
										<svg x="0px" y="0px" viewBox="0 0 33 16.3">
											<path id="Fill-1" class="st0" d="M33,7.2H3.5c2.3-1.4,5-2.9,7.4-5.9L9.4,0C6.9,3.1,3.6,5.6,0,7.2v1.8c3.6,1.6,6.9,4.1,9.4,7.2L11,15 c-2.4-2.9-5.2-4.5-7.4-5.9H33V7.2z"/>
										</svg>

									</div>
									<div className="next" onClick={this.goToNextSlide}>
										<svg x="0px" y="0px" viewBox="0 0 33 16.3">
											<path id="Fill-1" class="st0" d="M0,9.2h29.5c-2.3,1.4-5,2.9-7.4,5.9l1.5,1.3c2.6-3.1,5.8-5.6,9.4-7.2V7.2c-3.6-1.6-6.9-4.1-9.4-7.2	L22,1.3c2.4,2.9,5.2,4.5,7.4,5.9H0V9.2z"/>
										</svg>
									</div>
								</div>
							</div>
						</ScrollTransitionIn>
					</div>
				</section>
		
		);
	}
}

export default Slideshow;
