import React, { Component, Fragment } from 'react'
import Image from 'components/Image'

import './ImageFeed.scss';


class ImageFeed extends Component {

	componentWillMount () {
		
	}

// {images.map((item, index) => (
// 						<div className="col" key={item.id}>
// 							<a className="img-wrap" href={item.link} target="_blank">
// 								<img src={item.image} alt={item.alt} />
// 							</a>
// 						</div>
// 					))}


	render () {
		const { image1, image2, image3, image4, image5, lang } = this.props;

		let instaLink;

		if (lang === 'es-MX' ) {
			instaLink = 'https://www.instagram.com/cervezamonopolio/';
		} else {
			instaLink = 'https://www.instagram.com/cervezamonopolio/';
		}

		return (
			<div className="image-feed">
				<div className="grid-flex gutter-tight no-break image-grid">
					<div className="col">
						<a className="img-wrap" href={instaLink} target="_blank">
							<Image {...image1} />
						</a>
					</div>

					<div className="col">
						<a className="img-wrap" href={instaLink} target="_blank">
							<Image {...image2} />
						</a>
					</div>

					<div className="col">
						<a className="img-wrap" href={instaLink} target="_blank">
							<Image {...image3} />
						</a>
					</div>

					<div className="col">
						<a className="img-wrap" href={instaLink} target="_blank">
							<Image {...image4} />
						</a>
					</div>

					<div className="col">
						<a className="img-wrap" href={instaLink} target="_blank">
							<Image {...image5} />
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default ImageFeed;