import React, { Component } from 'react';

import Illo from 'assets/svg/cloud-4.svg';

class Cloud4 extends Component {

  render() {
    return (
			<div className="illo-wrap">
      	<Illo />
      </div> 
    );
  }
}

export default Cloud4;