import React, { Component } from 'react';

import Illo from 'assets/svg/cloud-3.svg';
			

class Cloud3 extends Component {

  render() {
    return (
			<div className="illo-wrap">
      	<Illo />
      </div> 
    );
  }
}

export default Cloud3;