import React, { Component } from 'react';

import Illo from 'assets/svg/cloud-2.svg';

class Cloud2 extends Component {

  render() {
    return (
			<div className="illo-wrap">
      	<Illo />
      </div>    
    );
  }
}

export default Cloud2;